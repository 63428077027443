import { RouteConfig } from "vue-router/types/router";
import { logRocketInit } from "../../core/config/initThirdParty";

export const PropagationRoutes: RouteConfig = {
  path: "/propagation",
  component: () => import("@/modules/propagation/views/Index.vue"),
  children: [
    {
      path: "",
      name: "PropagationMain",
      component: () => import("../views/Main.vue"),
    },
    {
      path: "details/:date/:cropOrRecipeId/:moduleId",
      props: true,
      name: "PropagationDetails",
      component: () => import("../views/Details.vue"),
    },
    {
      path: "directed/:date/:targetLineId",
      props: true,
      name: "DirectedPropDetails",
      component: () => import("../views/Details.vue"),
    },
    {
      path: "empty-bench-details/:date/:moduleId",
      props: true,
      name: "EmptyPropBenchDetails",
      component: () => import("../views/EmptyBenchDetails.vue"),
    },
  ],
  beforeEnter: (to, from, next) => {
    logRocketInit("propagation");
    next();
  },
};
