
// Importing the necessary modules from vue-property-decorator
import { Vue, Component } from "vue-property-decorator";

// Declaring the component using the @Component decorator
@Component
export default class CypressSelectors extends Vue {
  // Lifecycle hook that gets called when the component is mounted
  mounted() {
    // Retrieve the name of the parent component or use "no-component-name" if not available
    const parentComponentName = this.$options.name || "no-component-name";
    // Reference to the root DOM element of this Vue component
    const parentElement = this.$el;
    // Get all child elements of the parent component
    const childrenElements = parentElement.children;
    // Get all attributes of the parent element
    const parentElementAttributes = parentElement.attributes;

    // If the parent element has no text content, exit the function
    if (!parentElement.textContent || parentElement.textContent.length === 0)
      return;

    // If the parent element has attributes, add a custom Cypress selector to it
    if (parentElementAttributes)
      this.addCypressSelector(
        parentElement,
        parentElementAttributes,
        parentComponentName
      );

    // If the parent element has child elements, recursively process them
    if (childrenElements.length > 0)
      this.recursionIsTheMotion(childrenElements, parentComponentName);
  }

  // Recursively traverses through the child elements to add Cypress selectors
  recursionIsTheMotion(
    children: HTMLCollection,
    parentComponentName: string
  ): any {
    // Loop through each child element
    for (let i = 0; i < children.length; i++) {
      let child = children[i];
      // Add a Cypress selector to the current child element
      this.addCypressSelector(child, child.attributes, parentComponentName);

      // If the current child element has children, recurse into them
      if (child.children && child.children.length > 0) {
        this.recursionIsTheMotion(child.children, parentComponentName);
      }
    }
  }

  // Adds a custom Cypress data attribute to an element for testing purposes
  addCypressSelector(
    element: Element,
    attributes: NamedNodeMap,
    parentComponentName: string
  ) {
    // If the element already has a "data-cy" attribute, do nothing
    if (attributes["data-cy"]) return;

    // Create a new "data-cy" attribute
    const dataCy = document.createAttribute("data-cy");
    let dataCyValue;
    let textContent = element.textContent?.trim(); // Get trimmed text content

    // If text content is empty, use a fallback value
    if (!textContent) textContent = "fallback";

    // Array to store tags for constructing the "data-cy" value
    const tags = [];
    tags.push(parentComponentName); // Add the parent component's name
    tags.push(element.tagName); // Add the element's tag name

    // If the element is an input, add the label text associated with it, if any
    if (element.tagName.toLowerCase() === "input") {
      const label = document.querySelector(`label[for="${element.id}"]`);
      tags.push(label?.textContent);
    }

    // If only two tags are present, add the text content as a tag
    if (tags.length === 2) {
      tags.push(textContent);
    }

    // Construct the "data-cy" value by joining tags and formatting the string
    dataCyValue = tags.toString();
    dataCy.value = dataCyValue
      .toLowerCase()
      .replaceAll(",", "-") // Replace commas with hyphens
      .replaceAll(" ", "-"); // Replace spaces with hyphens

    // Set the constructed "data-cy" attribute on the element
    return attributes.setNamedItem(dataCy);
  }
}
