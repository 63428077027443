import {
  set,
  setDeep,
  retrieveObject,
  retrieveSet,
  sendQuery,
} from "@/util/vuex";

import {
  CropOrderLine,
  PropagationLine,
  PropagationTask,
  SeedingTask,
  TransplantTask,
  TaskStageHours,
} from "@eaua/model";
import axios from "axios";
import get from "lodash/get";
import merge from "lodash/merge";

const TASK_STORE_DEFAULTS = (): { [key: string]: any } => {
  return {
    seedingTasks: [],
    taskOrderIds: [],
    detailTaskOrderIds: [],
    detailTaskCropId: "",
    propagationTasks: [],
    propTasksReadyForTransplant: [],
    currentSeedingTask: {},
    refreshInterval: 60000,
    transplantTasks: [],
    currentTransplantTask: {},
    detailTasks: [],
    stageHours: [],
    propLines: [],
  };
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = TASK_STORE_DEFAULTS();

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setSeedingTasks: setDeep("seedingTasks"),
  setCurrentSeedingTask: set("currentSeedingTask"),
  setPropagationTasks: setDeep("propagationTasks"),
  setPropTasksReadyForTransplant: setDeep("propTasksReadyForTransplant"),
  setDetailTasks: setDeep("detailTasks"),
  setStageHours: setDeep("stageHours"),
  setTaskOrderIds(state: any, payload: any) {
    state.taskOrderIds = (payload.list || []).map(
      (t: any) => t.order_header_id
    );
  },
  setDetailTaskOrderIds(state: any, payload: any) {
    state.detailTaskOrderIds = (payload.list || []).map(
      (t: any) => t.order_header_id
    );
    state.detailTaskCropId = payload?.list?.[0]?.crop_id || "";
  },
  setTransplantTasks: setDeep("transplantTasks"),
  setCurrentTransplantTask: set("currentTransplantTask"),
  setPropLines: setDeep("propLines"),
  reset(state: any, property: string) {
    state[property] = TASK_STORE_DEFAULTS()[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // PROPAGATION TASKS --------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves a list of propagation tasks for the current facility
   * Commits order using `setPropagationTasks`.
   * @param context Store module context
   */
  retrievePropagationTasks: function (
    context: any,
    { where = null, isDetailsPage = false }: any = {}
  ) {
    const template = new PropagationTask();
    // Build or combine where statements
    const defaultWhere = {
      facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
    };

    // Build return string
    let returnQuery: string = template.getReturnQuery(
      "$where",
      "{ due_date: asc }",
      template.viewQueryReturn,
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      isDetailsPage ? "setDetailTasks" : "setPropagationTasks",
      template.view,
      { where: merge(where || {}, defaultWhere) }
    );
  },

  /**
   * Retrieves a list of propagation tasks for the current facility
   * Commits order using `setPropagationTasks`.
   * @param context Store module context
   */
  retrievePropTasksReadyForTransplant: function (
    context: any,
    { where = null }: any = {}
  ) {
    const template = new PropagationTask();
    // Build or combine where statements
    const defaultWhere = {
      facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
    };

    // Build return string
    let returnQuery: string = template.getReturnQuery(
      "$where",
      "{ due_date: asc }",
      template.viewQueryReturn,
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setPropTasksReadyForTransplant",
      template.view,
      { where: merge(where || {}, defaultWhere) }
    );
  },
  // SEEDING TASKS -----------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves a list of seeding tasks for the current facility
   * Commits order using `setSeedingTasks`.
   * @param context Store module context
   */
  retrieveSeedingTasks: function (
    context: any,
    { where = null, minified = false, isDetailsPage = false }: any = {}
  ) {
    const taskTemplate = new SeedingTask();
    // Build or combine where statements
    const defaultWhere = {
      facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
    };
    if (where) where = merge(where, defaultWhere);
    else where = defaultWhere;

    // Build return string
    let returnQuery: string = taskTemplate.getReturnQuery(
      "$where",
      "",
      minified
        ? taskTemplate.getMinifiedQueryReturnTemplate(true)
        : taskTemplate.viewQueryReturn,
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      isDetailsPage ? "setDetailTasks" : "setSeedingTasks",
      taskTemplate.view,
      {
        where: where,
      }
    );
  },

  /**
   * Retrieves a list of seeding task order ids
   * Commits order using `setSeedingTasks`.
   * @param context Store module context
   */
  retrieveTaskOrderIds: function (
    context: any,
    { where = null, isDetailsPage = false }: any = {}
  ) {
    const taskTemplate = new SeedingTask();
    // Build or combine where statements
    const defaultWhere = {
      facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
    };
    if (where) where = merge(where, defaultWhere);
    else where = defaultWhere;

    // Build return string
    let returnQuery: string = taskTemplate.getReturnQuery(
      "$where",
      "",
      "order_header_id crop_id",
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      isDetailsPage ? "setDetailTaskOrderIds" : "setTaskOrderIds",
      taskTemplate.view,
      {
        where: where,
      }
    );
  },

  /**
   * Retrieves a seeding task, given its uuid
   * Commits order using `setCurrentSeedingTask`.
   * @param context Store module context
   */
  retrieveSeedingTask: function (context: any, { uuid }: any) {
    const taskTemplate = new SeedingTask();
    let returnQuery: string = taskTemplate.getReturnQuery("$where");
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentSeedingTask",
      taskTemplate.table,
      { where: { uuid: { _eq: uuid } } }
    );
  },

  // TRANSPLANT TASKS --------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves a list of transplant tasks for the current facility
   * Commits order using `setTransplantTasks`.
   * @param context Store module context
   */
  retrieveTransplantTasks: function (
    context: any,
    { where = null, isDetailsPage = false }: any = {}
  ) {
    const template = new TransplantTask();
    // Build or combine where statements
    const defaultWhere = {
      facility_id: { _eq: context.rootState.facilities.currentFacilityUuid },
    };

    // Build return string
    let returnQuery: string = template.getReturnQuery(
      "$where",
      "{ due_date: asc }",
      template.viewQueryReturn,
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      isDetailsPage ? "setDetailTasks" : "setTransplantTasks",
      template.view,
      { where: merge(where || {}, defaultWhere) }
    );
  },

  /**
   * Retrieves a transplant task, given its uuid
   * Commits order using `setCurrentTransplantTask`.
   * @param context Store module context
   */
  retrieveTransplantTask: function (context: any, { uuid }: any) {
    let taskTemplate = new TransplantTask();
    const returnQuery: string = taskTemplate.getReturnQuery(
      `{ uuid: { _eq: "${uuid}" }}`
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentTransplantTask",
      taskTemplate.table
    );
  },

  /**
   * Saves a crop order line. Called to complete a line
   * @param context
   * @param cropOrderLine
   */
  saveCropOrderLine: function (context: any, { cropOrderLine }: any) {
    const template = new CropOrderLine();
    const upsertQuery: string = template.getUpsertQuery();
    if (!upsertQuery) return;

    return sendQuery(context, upsertQuery, { input: cropOrderLine });
  },

  /**
   * Transplants an order into a line
   */
  transplantOrderLine: function (context: any, { payload }: any) {
    payload.userId = get(context, "rootState.user.profile.uuid", "");
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_API_URL + "/v2/transplant-order", payload)
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Transplants an empty bench into a prop line
   */
  transplantEmptyPropBench: function (context: any, { payload }: any) {
    payload.userId = get(context, "rootState.user.profile.uuid", "");
    return new Promise((resolve, reject) => {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/v2/transplant-empty-bench",
          payload
        )
        .then(
          (success: any) => {
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Updates a given crop order line with sent_to_controls set to true.
   * @param context Store module context
   */
  updateControlStatus: function (
    context: any,
    { orderId, lineId, isReceiving = false }: any
  ) {
    if (!orderId || !lineId) return;
    const column = isReceiving ? "received_from_controls" : "sent_to_controls";
    const query = `
      mutation updateSentToControls($order_id: uuid, $line_id: uuid) {
        update_growops_crop_order_lines(
          where: {
            order_id: {_eq: $order_id}, 
            destination_line_id: {_eq: $line_id},
          }, 
          _set: {${column}: true}
        ) {
          affected_rows
        }
      }`;

    return sendQuery(context, query, {
      order_id: orderId,
      line_id: lineId,
    });
  },

  /**
   * Retrieves stage hours given a crop id
   * Commits stage hours using `setStageHours`.
   * @param context Store module context
   */
  retrieveStageHours: function (context: any, { cropOrRecipeId }: any) {
    const template = new TaskStageHours();
    let returnQuery: string = template.getReturnQuery(
      "$where",
      "",
      "",
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setStageHours", template.view, {
      where: {
        _or: [
          { recipe_version_id: { _eq: cropOrRecipeId } },
          {
            recipe_version_id: { _is_null: true },
            crop_id: { _eq: cropOrRecipeId },
          },
        ],
      },
    });
  },

  retrievePropLinesWithEmptyBenches: function (
    context: any,
    { moduleId = "" }
  ) {
    const where: any = { empty_benches: { _is_null: false } };
    if (moduleId) where.module_id = { _eq: moduleId };
    const template = new PropagationLine();

    return retrieveSet(
      context,
      template.getReturnQuery("$where"),
      "setPropLines",
      template.view,
      {
        where: where,
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
