import {
  set,
  setDeep,
  retrieveObject,
  retrieveSet,
  sendQuery,
} from "@/util/vuex";
import { filterItems } from "@/util/helpers";
import { concat, find, get, groupBy, map, flatten } from "lodash";
import axios from "axios";
import Facility from "@/modules/core/models/shared/Facility";
import Plot from "@/modules/core/models/shared/Plot";
import Zone from "@/modules/core/models/shared/Zone";
import {
  FacilityLine,
  FacilityModule,
  CropStage,
  WaterSystem,
} from "@eaua/model";
import Vue from "vue";

function isFacilityCode(code: string = "") {
  return code.length === 3;
}

const FACILITY_STORE_DEFAULTS: { [key: string]: any } = {
  facilities: [],
  currentFacility: "",
  currentFacilityUuid: "",
  currentFacilityLocations: [],
  waterSystems: [],
  currentWaterSystemId: "",
  modules: [],
  currentModule: {},
  currentModuleLine: {},
  currentModuleLines: [],
  lineOptions: [],
  currentPlot: {},
  plots: [],
  currentHarvestPlot: null,
  plotOptions: {},
  zoneOptions: {},
  refreshInterval: 60000,
};

// ---------------------------------------------------------------------------
// STATE
// ---------------------------------------------------------------------------

const state = () => {
  let state: any = {};
  for (let property in FACILITY_STORE_DEFAULTS) {
    state[property] = FACILITY_STORE_DEFAULTS[property];
  }
  return state;
};

// ---------------------------------------------------------------------------
// MUTATIONS
// ---------------------------------------------------------------------------

const mutations = {
  setFacilities: setDeep("facilities"),
  setCurrentFacility: set("currentFacility"),
  setCurrentFacilityUuid: set("currentFacilityUuid"),
  setWaterSystems: setDeep("waterSystems"),
  setCurrentWaterSystemId: set("currentWaterSystemId"),
  setModules: setDeep("modules"),
  setCurrentModule: set("currentModule"),
  setCurrentModuleLine: set("currentModuleLine"),
  setCurrentModuleLines: setDeep("currentModuleLines"),
  setLineOptions: setDeep("lineOptions"),
  setCurrentPlot: set("currentPlot"),
  setPlots: setDeep("plots"),
  setHarvestPlot: set("currentHarvestPlot"),
  setPlotOptions(state: any, payload: any) {
    let options = groupBy(payload.list, "zone_id");
    for (let zoneId in options) {
      if (zoneId) state.plotOptions[zoneId] = options[zoneId];
    }
  },
  setZoneOptions(state: any, payload: any) {
    let options = groupBy(payload.list, "facility_id");
    for (let facilityId in options) {
      Vue.set(state.zoneOptions, facilityId, options[facilityId]);
    }
  },
  setCurrentFacilityLocations: setDeep("currentFacilityLocations"),
  reset(state: any, property: string) {
    if (property === "plotOptions") state[property] = {};
    else state[property] = FACILITY_STORE_DEFAULTS[property];
  },
};

// ---------------------------------------------------------------------------
// GETTERS
// ---------------------------------------------------------------------------

const getters = {
  // FACILITIES --------------------------------------------------------------
  /**
   * Returns the current facility object
   * @returns {Object}
   */
  getCurrentFacility: (state: any) => {
    let currentFacility = {};
    let facilities: Array<any> = state.facilities;
    if (state.currentFacilityUuid) {
      let currentFacilityUuid = state.currentFacilityUuid;
      currentFacility = facilities.find(
        (fac) => fac.uuid === currentFacilityUuid
      );
    } else if (state.currentFacility) {
      let currentCode = state.currentFacility;
      currentFacility = facilities.find((fac) => fac.code === currentCode);
    }
    return currentFacility || {};
  },

  /**
   * Returns a facility object given a code
   * @param {string} code Facility code
   * @returns {Object}
   */
  getFacilityByCode:
    (state: any) =>
    (code: string = "") => {
      if (!code) return {};
      return state.facilities.find((fac: any) => fac.code === code) || {};
    },

  /**
   * Returns a facility object given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getFacilityByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return state.facilities.find((fac: any) => fac.uuid === uuid) || {};
    },

  /**
   * Returns facility name for a given facility code or current facility
   * @param {string} code Facility code or uuid
   * @returns {string}
   */
  getFacilityName:
    (state: any, getters: any) =>
    (code: string = "") => {
      // Checks if `code` is a facility code or uuid
      let isCode: boolean = isFacilityCode(code);
      let facility: any = {};
      // If `code` is passed, and it's a facility code,
      // set the facility using `getFacilityByCode`
      if (code && isCode) {
        facility = getters["getFacilityByCode"](code);
      }
      // If `code` is passed, and it's a facility uuid,
      // set the facility using `getFacilityByUuid`
      else if (code && !isCode) {
        facility = getters["getFacilityByUuid"](code);
      }
      // If no `code` is passed, set facility to current facility
      else facility = getters["getCurrentFacility"];
      return facility.name || "";
    },

  /**
   * Returns facility uuid options for use in a select menu
   * @returns {Array}
   */
  getFacilityOptions: (state: any, getters: any) => {
    return (
      map(state.facilities, (facility: any) => {
        return { text: facility.name, value: facility.uuid };
      }) || []
    );
  },

  /**
   * Deprecated. Alias for `getFacilityOptions`
   * @returns {Array}
   */
  getFacilityUuidOptions: (state: any, getters: any) => {
    return getters["getFacilityOptions"];
  },

  /**
   * Returns facility code options for use in a select menu
   * @returns {Array}
   */
  getFacilityCodeOptions: (state: any, getters: any) => {
    return (
      map((facility: any) => {
        return {
          text: facility.name,
          value: facility.code,
        };
      }, state.facilities) || []
    );
  },

  // MODULES -----------------------------------------------------------------
  /**
   * Returns a module object given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getModuleByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find(state.modules, { uuid: uuid }) || {};
    },

  /**
   * Returns growing module options for use in a select menu
   * @returns {Array}
   */
  getModuleOptions:
    (state: any) =>
    (stages: string[] = []) => {
      return (state.modules || []).reduce((acc: any[], m: any) => {
        const stage = new CropStage(m.rel_crop_stage || {}).getDisplayVersion();
        if (stages.length === 0 || stages.includes(stage.value)) {
          acc.push({
            text: m.name,
            value: m.uuid,
            icon: {
              ...stage.icon,
              size: "16",
              class: "mr-2",
            },
          });
        }
        return acc;
      }, []);
    },

  /**
   * Returns a module line given a uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getModuleLineByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      return find(state.currentModuleLines, { uuid: uuid }) || {};
    },

  /**
   * Returns growing module line options for use in a select menu
   * @returns {Array}
   */
  getModuleLineOptions:
    (state: any) =>
    (includeModuleName = false) => {
      return map(state.lineOptions, (l: any) => {
        let name = l.name;
        const moduleName = get(l, "rel_module.name", "");
        if (includeModuleName && moduleName) name = `${moduleName} ${name}`;
        return {
          text: name,
          value: l.uuid,
        };
      });
    },

  // PLOTS -------------------------------------------------------------------
  /**
   * Returns an array of sorted plots for a facility
   * @param {string} uuid A facility uuid
   * @returns {Array}
   */
  getPlotsByFacility:
    (state: any, getters: any) =>
    (uuid: string = "") => {
      let facility: any = uuid
        ? getters["getFacilityByUuid"](uuid)
        : getters["getCurrentFacility"];
      if (!facility.rel_zones) return [];
      let plots: any = flatten(facility.rel_zones.map((z: any) => z.rel_plots));
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted plots
      return plots.sort((a: any, b: any) => collator.compare(a.name, b.name));
    },

  /**
   * Returns an array of sorted plots for a given zone
   * @param {string} zone_id A zone uuid
   * @returns {Array}
   */
  getPlotsByZone:
    (state: any, getters: any) =>
    (zone_id: string = "") => {
      let zone: any = getters["getZoneByUuid"](zone_id);
      let plots: any = zone.rel_plots || [];
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted plots
      return plots
        .filter((p: any) => p.active)
        .sort((a: any, b: any) => collator.compare(a.name, b.name));
    },

  /**
   * Returns an array of plot dropdown options for a given zone
   * @param {string} zone_id A zone uuid
   * @returns {Array}
   */
  getPlotOptionsByZone:
    (state: any, getters: any) =>
    (zone_id: string = "") => {
      return getters["getPlotsByZone"](zone_id).map((p: any) => {
        return {
          text: p.name,
          value: p.uuid,
          // This is a dumb workaround, that will hopefully be solved
          // with a proper plots view. Blame me, I deserve it -jt
          line_id: p.line_id,
        };
      });
    },

  /**
   * Returns an array of plot options to be used as a standard
   * app-wide. Do not change unless something is broken.
   * @returns {Array}
   */
  getPlotOptionsForStandardSelection:
    (state: any, getters: any, rootState: any) =>
    (
      zone_id: string = "",
      showAvailable: boolean = false,
      showTrays: boolean = false,
      showOccupied: boolean = false,
      showLine: boolean = false,
      showOrder: boolean = true
    ) => {
      if (!zone_id) return [];
      let plots: any = state.plotOptions[zone_id] || [];
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Sort plots
      plots = plots
        .filter((p: any) => p.active)
        .sort((a: any, b: any) => collator.compare(a.name, b.name));

      let out: any = [];
      let usedPlots: any = [{ header: "In Use" }];
      let openPlots: any = [{ header: "Available" }];
      let currentCropUuid: string = get(
        rootState,
        "cropOrder.currentCropOrder.uuid",
        ""
      );

      // Add plots to either usedPlots or openPlots
      for (let plot of plots) {
        // Get associated mfg order ids
        let orderIds: any = (plot.active_crop_orders || []).map(
          (c: any) => c.uuid
        );
        // Get associated mfg order names
        let orderNames: any = (plot.active_crop_orders || []).map(
          (c: any) => c.mfg_name
        );

        // Add line name, trays used, and order names to the plot name
        let name: string = plot.name;
        if (showLine && plot.line_name) name += ` - ${plot.line_name}`;
        if (showTrays && plot.max_trays) {
          name += " - (";
          name += plot.tray_count || "0";
          name += `/${plot.max_trays} trays)`;
        }
        if (showOrder && orderNames.length > 0) {
          name += " - ";
          name += orderNames.join(", ");
        }
        // If showOccupied is true, only add plots that have the current
        // mfg order
        if (showOccupied) {
          if (orderIds.length > 0 && orderIds.includes(currentCropUuid)) {
            out.push({
              text: name,
              value: plot.uuid,
            });
          }
        }
        // If showAvailable is true, organize plots into used and open
        else if (showAvailable) {
          // If this plot has orders (excluding the current order) or
          // there's no tray space available, add to the used array
          if (
            (!plot.allow_multiple_orders &&
              orderIds.length > 0 &&
              !orderIds.includes(currentCropUuid)) ||
            (plot.tray_space_available === 0 &&
              !orderIds.includes(currentCropUuid))
          ) {
            usedPlots.push({
              text: name,
              value: plot.uuid,
              disabled: true,
            });
          }
          // Otherwise, the plot should be open
          else {
            openPlots.push({
              text: name,
              value: plot.uuid,
              disabled: false,
            });
          }
        }
        // If not showAvailable and not showOccupied, just add
        // each plot
        else {
          out.push({
            text: name,
            value: plot.uuid,
          });
        }
      }

      if (showAvailable) return concat(openPlots, usedPlots);
      return out;
    },

  // WATER SYSTEMS -----------------------------------------------------------
  /**
   * Returns water system options for use in a select menu
   * @returns {Array}
   */
  getWaterSystemOptions: (state: any) => {
    return map((ws: any) => {
      return {
        text: ws.name,
        value: ws.uuid,
      };
    }, state.waterSystems);
  },

  // ZONES -------------------------------------------------------------------
  /**
   * Returns a zone for a given uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getZoneByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      for (let facility of state.facilities) {
        let zone: any = facility.rel_zones.find(
          (zone: any) => zone.uuid === uuid
        );
        if (zone) return zone;
      }
      return {};
    },

  /**
   * Returns a zone name for a given uuid
   * @param {string} uuid
   * @returns {string}
   */
  getZoneNameByUuid:
    (state: any, getters: any) =>
    (uuid: string = "") => {
      if (!uuid) return "";
      let zone = getters["getZoneByUuid"](uuid);
      return zone ? zone.name : "";
    },

  /**
   * Returns an array of sorted zones for a facility
   * @param {string} uuid A facility uuid
   * @returns {Array}
   */
  getZonesByFacility:
    (state: any, getters: any) =>
    (uuid: string = "") => {
      let facility: any = uuid
        ? getters["getFacilityByUuid"](uuid)
        : getters["getCurrentFacility"];
      if (!facility.rel_zones) return [];
      let zones: any = facility.rel_zones;
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted zones
      return zones.sort((a: any, b: any) => collator.compare(a.name, b.name));
    },

  /**
   * Returns array of zones for a given facility code or current facility
   * for use in a select menu
   * @param {string} facility_id Facility uuid
   * @returns {Array}
   */
  getZoneOptions:
    (state: any, getters: any) =>
    (facility_id: string = "") => {
      let facility: any = facility_id
        ? getters["getFacilityByUuid"](facility_id)
        : getters["getCurrentFacility"];
      // Map zones to text and value options
      let zones: any = (facility.rel_zones || [])
        // filter the archived zones
        .reduce((filterZones: Array<any>, zones: any) => {
          if (zones.active) {
            filterZones.push({
              text: zones.name,
              value: zones.uuid,
              type: zones.type,
            });
          }
          return filterZones;
        }, []);
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted zones
      return zones.sort((a: any, b: any) => collator.compare(a.text, b.text));
    },

  // FILTERS -----------------------------------------------------------------
  /**
   * Returns an array of filtered facilities for the route `purchase-facilities`
   * @returns {Array}
   */
  getFilteredFacilities: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    let filters = rootGetters["filter/getRoutedFilters"]["facilities"] || [];
    let search = rootState.filter.search["facilities"] || "";
    return filterItems(state.facilities, filters, search);
  },
  // LOCATION ROOMS ----------------------------------------------------------
  /**
   * Returns an array of location room dropdown options
   * @returns {Array}
   */
  locationRoomOptions: (state: any) => {
    return state.currentFacilityLocations.map((l: any) => {
      return {
        value: l.uuid,
        text: l.name,
      };
    });
  },

  getLocationByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      let locationRooms: Array<any> = state.currentFacilityLocations;
      let location!: any;
      locationRooms.forEach((locationRoom) => {
        if (!location) {
          let locations: Array<any> = locationRoom.rel_locations;
          location = locations.find((loc) => {
            return loc.uuid == uuid;
          });
        }
      });
      return location || {};
    },
  getHoldRoom: (state: any) => {
    const currentFacility = state.facilities.find(
      (facility: any) => facility.uuid === state.currentFacilityUuid
    );
    return get(currentFacility, "details.holdRoom", "");
  },
  /**
   * Returns a zone for a given uuid
   * @param {string} uuid
   * @returns {Object}
   */
  getLocationRoomByUuid:
    (state: any) =>
    (uuid: string = "") => {
      if (!uuid) return {};
      let currentFacilityLocations: Array<any> = state.currentFacilityLocations;
      let location = currentFacilityLocations.find((location) => {
        return location.uuid == uuid;
      });

      return location || {};
    },

  /**
   * Returns an array of sorted plots for a given zone
   * @param {string} room_id A zone uuid
   * @returns {Array}
   */
  getLocationsByLocationRoom:
    (state: any, getters: any) =>
    (room_id: string = "") => {
      let room: any = getters["getLocationRoomByUuid"](room_id);
      let locations: any = room.rel_locations || [];
      // Init the collator
      // Collators are faster than localeCompare
      let collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });
      // Return sorted location rooms
      return locations.sort((a: any, b: any) =>
        collator.compare(a.name, b.name)
      );
    },

  getDefaultHarvestLocation: (state: any, getters: any) => {
    let found = getters["getLocationByUuid"](
      getters["getCurrentFacility"].details.default_harvest
    );
    return found;
  },
};

// ---------------------------------------------------------------------------
// ACTIONS
// ---------------------------------------------------------------------------

const actions = {
  // FACILITIES --------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Retrieves all facilities with or without zones and plots, sorted by code.
   * Commits facilities using `setFacilities`.
   * @param context Store module context
   * @param detailed True if facilities should return plot and zone info too
   */
  retrieveFacilities: function (context: any, { detailed = false }: any = {}) {
    let facilityTemplate = new Facility();
    let returnQuery: string = facilityTemplate.getReturnQuery(
      "",
      `{ code: asc }`,
      detailed ? facilityTemplate.queryReturnWithPlots : ""
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setFacilities",
      facilityTemplate.table
    );
  },
  retrieveLocation: function (context: any, { uuid }: any = "") {
    if (!uuid) return;
    let location = context.getters.getLocationByUuid(uuid);

    return context.commit("setCurrentLocation", location);
  },
  //retrieves all locations for QR generations.
  retrieveLocationRooms: function (context: any, facilityId: string = "") {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `query InventoryLocations(
            $facility_uuid: uuid!
          ) {
            growops_inventory_location_rooms(
              where: {
                facility_id: {
                  _eq: $facility_uuid 
                }
              },
              order_by: { name: asc }
            ) {            
               name
               uuid
              rel_locations {
               uuid
               name
               facility_code
               facility_id
               description
               location_row
               location_column
               room_id
              }
            }
          }`,
          variables: {
            facility_uuid: facilityId || context.state.currentFacilityUuid,
          },
        })
        .then(
          (success: any) => {
            context.commit("setCurrentFacilityLocations", {
              list: success.data.data.growops_inventory_location_rooms,
            });
            resolve(success);
          },
          (fail: any) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // PLOTS -------------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Saves a plot.
   * Updates facilities by dispatching `retrieveFacilities`.
   * @param context Store module context
   * @param {Object} plot Plot save-version object
   */
  savePlot: function (context: any, { plot }: any) {
    let plotTemplate = new Plot();
    let upsertQuery: string = plotTemplate.getUpsertQuery(
      plotTemplate.queryReturn
    );
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: plot,
          },
        })
        .then(
          (success: any) => {
            // Refresh facilities
            context.dispatch("retrieveFacilities", { detailed: true });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  saveFacilityDetails: function (context: any, { facility }: any) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: `mutation upsertFacilityDetails(
            $facility: [shared_facilities_insert_input!]!
          ) {
            insert_shared_facilities(
              objects: $facility,
              on_conflict: {
                constraint: facilities_pkey,
                update_columns: [details]
              }
            ) {
              returning {
                uuid
                details
              }
            }
          }`,
          variables: {
            facility: facility,
          },
        })
        .then(
          (success: any) => {
            context.dispatch("retrieveFacilities", { detailed: true });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves a plot by uuid.
   * Commits plot using `setCurrentPlot`.
   * @param context Store module context
   * @param {string} uuid Plot UUID
   */
  retrievePlot: function (context: any, { uuid, useView = false }: any) {
    let plotTemplate = new Plot();
    let returnQuery: string = plotTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`,
      "",
      useView ? plotTemplate.getQueryReturnTemplate(true) : "",
      {},
      useView
    );
    if (!returnQuery) return;
    return retrieveObject(
      context,
      returnQuery,
      "setCurrentPlot",
      useView ? plotTemplate.view : plotTemplate.table
    );
  },

  /**
   * Retrieves plots.
   * Commits plot using `setPlots`.
   * @param context Store module context
   */
  retrievePlots: function (
    context: any,
    { where = "", orderBy = "", useView = false }: any
  ) {
    let plotTemplate = new Plot();
    let returnQuery: string = plotTemplate.getReturnQuery(
      where,
      orderBy,
      useView ? plotTemplate.getQueryReturnTemplate(true) : "",
      {},
      useView
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setPlots",
      useView ? plotTemplate.view : plotTemplate.table
    );
  },

  /**
   * Retrieves plot options from view for a selection menu.
   * Commits plot using `setPlotOptions`.
   * @param context Store module context
   */
  retrievePlotOptions: function (
    context: any,
    { where = "", orderBy = "" }: any = {}
  ) {
    let plotTemplate = new Plot();
    let returnQuery: string = plotTemplate.getReturnQuery(
      where,
      orderBy,
      plotTemplate.getQueryReturnTemplate(true),
      {},
      true
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setPlotOptions",
      plotTemplate.view
    );
  },

  /**
   * Retrieves zone options for a selection menu.
   * Commits plot using `setZoneOptions`.
   * @param context Store module context
   */
  retrieveZoneOptions: function (
    context: any,
    { where = "", orderBy = "" }: any = {}
  ) {
    const template = new Zone();
    const returnQuery = template.getReturnQuery(where, orderBy);
    if (!returnQuery) return;

    return retrieveSet(context, returnQuery, "setZoneOptions", template.table);
  },

  /**
   * Retrieves the harvest plot.
   * Commits orders using `setHarvestPlot`.
   * @param context Store module context
   */
  retrieveHarvestPlot: function (context: any, { zoneId, lineId }: any) {
    const plotTemplate = new Plot();

    const returnQuery: string = plotTemplate.getReturnQuery(
      "$where",
      "",
      "uuid"
    );

    return retrieveObject(
      context,
      returnQuery,
      "setHarvestPlot",
      plotTemplate.table,
      {
        where: {
          type: { _eq: "harvest" },
          line_id: lineId ? { _eq: lineId } : undefined,
          zone_id: zoneId ? { _eq: zoneId } : undefined,
        },
      }
    );
  },

  // WATER SYSTEMS -----------------------------------------------------------
  // -------------------------------------------------------------------------

  /**
   * Retrieves all water systems, sorted by name.
   * Commits systems using `setWaterSystems`.
   * @param context Store module context
   */
  retrieveWaterSystems: function (context: any, { facilityId = "" }: any = {}) {
    facilityId = facilityId || context.state.currentFacilityUuid;
    let systemTemplate = new WaterSystem();
    let returnQuery: string = systemTemplate.getReturnQuery(
      `{ facility_id: {_eq: "${facilityId}"}}`,
      `{ name: asc }`,
      systemTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setWaterSystems",
      systemTemplate.table
    );
  },

  // ZONES -------------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Saves a zone.
   * Updates facilities by dispatching `retrieveFacilities`.
   * @param context Store module context
   * @param {Object} zone Zone save-version object
   */
  saveZone: function (context: any, { zone }: any) {
    let zoneTemplate = new Zone();
    let upsertQuery: string = zoneTemplate.getUpsertQuery(
      zoneTemplate.queryReturn
    );
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: zone,
          },
        })
        .then(
          (success: any) => {
            // Refresh facilities
            context.dispatch("retrieveFacilities", { detailed: true });
            resolve(success);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  // MODULES -----------------------------------------------------------------
  // -------------------------------------------------------------------------
  /**
   * Saves a growing module.
   * Commits saved system using `setCurrentModule`.
   * @param context Store module context
   * @param {Object} module Module save-version object
   */
  saveModule: function (
    context: any,
    { module, relatedIdsToDelete = [] }: any
  ) {
    const template = new FacilityModule();
    const varDefs: any = { $input: template.insertType };
    const varVals: any = { input: module };

    // Handle deleting module relationships
    let inject = "";
    if (module.uuid && relatedIdsToDelete.length > 0) {
      varDefs.$id = "uuid";
      varVals.id = module.uuid;

      varDefs.$moduleIds = "[uuid!]!";
      varVals.moduleIds = relatedIdsToDelete;

      inject = `
        delete_children: delete_shared_related_facility_modules(
          where: {
            module_id_1: {_eq: $id},
            module_id_2: {_in: $moduleIds}
          }) {
          affected_rows
        }

        delete_parents: delete_shared_related_facility_modules(
          where: {
            module_id_1: {_in: $moduleIds},
            module_id_2: {_eq: $id}
          }) {
          affected_rows
        }
      `;
    }
    const upsertQuery = template.getUpsertQuery("", inject, varDefs);
    if (!upsertQuery) return;

    return sendQuery(
      context,
      upsertQuery,
      varVals,
      "setCurrentModule",
      `insert_${template.table}`,
      true
    );
  },

  /**
   * Retrieves a growing module by uuid.
   * Commits system using `setCurrentModule`.
   * @param context Store module context
   * @param {string} uuid Module UUID
   */
  retrieveModule: function (context: any, { uuid, includeLines = false }: any) {
    let moduleTemplate = new FacilityModule();
    let returnQuery: string = moduleTemplate.getReturnQuery(
      `{uuid: {_eq: "${uuid}"}}`,
      "",
      includeLines
        ? moduleTemplate.queryReturnWithLines
        : moduleTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentModule",
      moduleTemplate.table
    );
  },

  /**
   * Retrieves all growing modules for a facility, sorted by name.
   * Commits modules using `setModules`.
   * @param context Store module context
   */
  retrieveModules: function (
    context: any,
    { facilityId = "", includeLines = false }: any = {}
  ) {
    facilityId = facilityId || context.state.currentFacilityUuid;
    let moduleTemplate = new FacilityModule();
    let returnQuery: string = moduleTemplate.getReturnQuery(
      `{ facility_id: {_eq: "${facilityId}"}, active: {_eq: true}}`,
      `{ name: asc }`,
      includeLines
        ? moduleTemplate.queryReturnWithLines
        : moduleTemplate.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      "setModules",
      moduleTemplate.table
    );
  },

  /**
   * Saves a growing line.
   * Dispatches `retrieveLines`
   * @param context Store module context
   * @param {Object} line Line save-version object
   */
  saveLine: function (context: any, { line }: any) {
    let lineTemplate = new FacilityLine();
    let upsertQuery: string = lineTemplate.getUpsertQuery();
    if (!upsertQuery) return;

    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_GRAPHQL_HTTP || "", {
          query: upsertQuery,
          variables: {
            input: line,
          },
        })
        .then(
          (success: any) => {
            resolve(success.data.data);
          },
          (fail) => {
            reject(new Error(fail.status));
          }
        );
    });
  },

  /**
   * Retrieves a module line by uuid
   * Commits line using `setCurrentModuleLine`.
   * @param context Store module context
   */
  retrieveLine: function (context: any, { uuid = "" }: any = {}) {
    if (!uuid) return;
    let lineTemplate = new FacilityLine();
    let returnQuery: string = lineTemplate.getReturnQuery(
      `{ uuid: {_eq: "${uuid}"} }`,
      "",
      lineTemplate.queryReturnWithPlots
    );
    if (!returnQuery) return;

    return retrieveObject(
      context,
      returnQuery,
      "setCurrentModuleLine",
      lineTemplate.table
    );
  },

  /**
   * Retrieves all growing lines for a module and level, sorted by row.
   * Commits lines using `setCurrentModuleLines`.
   * @param context Store module context
   */
  retrieveLines: function (
    context: any,
    {
      moduleIds = "",
      level = "",
      row = "",
      includeTargetedOrders = false,
      forSelection = false,
    }: any = {}
  ) {
    if (!moduleIds) return;
    if (!Array.isArray(moduleIds)) moduleIds = [moduleIds];

    const where: any = {
      active: { _eq: true },
      module_id: { _in: moduleIds },
    };
    if (level) where.level = { _eq: level };
    if (row) where.row = { _eq: row };

    const template = new FacilityLine();
    const returnQuery = template.getReturnQuery(
      "$where",
      `{ level: asc, row: asc }`,
      includeTargetedOrders
        ? template.queryReturnWithTargetOrders
        : template.queryReturn
    );
    if (!returnQuery) return;

    return retrieveSet(
      context,
      returnQuery,
      forSelection ? "setLineOptions" : "setCurrentModuleLines",
      template.table,
      { where: where }
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
